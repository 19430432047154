.nice-container {
  min-height: 100vh;
  padding: 60px 40px;
  font-family: "Open Sans", sans-serif;

  &-title {
    font-size: 30px;
    font-weight: 600;
    color: var(--gray-800);
  }

  &-tabs {
    &-wrapper {
      display: flex;
      justify-content: space-between;
    }

    .ant-tabs-tab {
      margin-left: 0 !important;
      margin-right: 40px !important;
      padding: 0 0 6px !important;

      & + & {
        margin-left: 40px;
      }
    }

    .ant-tabs-nav {
      margin-bottom: 0 !important;
    }

    .ant-tabs-nav-list {
      border-bottom: 2px solid var(--gray-400);

      .ant-tabs-tab + .ant-tabs-tab {
        margin-right: 0 !important;
      }
    }
  }
}
