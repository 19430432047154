@import "../../styles/globals";

.certification-popup {
  width: 100%;
  padding: 10px 25px;

  .certification-warning {
    height: 100%;
    float: left;
    background-color: $warning-color;
    border-radius: 4px;
    padding: 10px 10px 10px 15px;
  }

  svg {
    font-size: $font-medium;
    margin-right: 5px;
    color: $text-color;
  }

  .certification-body {
    display: flex;
    background-color: $background-color;
    border-bottom: 1px solid $gray;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    align-items: center;
    height: 43px;
    left: 215px;
    top: 64px;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
  }

  .certification-body-title {
    font-size: $font-medium;
    color: $sub-title-color;
    margin-left: 15px;
    font-weight: 500;
  }

  .certification-body-text {
    color: $description-color;
    font-size: $font-medium;
    margin-left: 5px;
  }

  .certification-button {
    background-color: $button-color;
    color: $white;
    margin-left: 36px;
    height: 25px;
    border: none;
    padding-top: 2px;
  }

  .certification-learn-more-button {
    color: $main-color;
    background-color: transparent;
    margin-left: 18px;
    broder: none;
    cursor: pointer;
  }
}
