@import "../../styles/globals";
@import "~@bringg/react-components/dist/components/task-status/task-status";
@import "~@bringg/react-components/dist/components/tabs/tabs";
@import "~@bringg/react-components/dist/features/waypoint-card/waypoint-card";
@import "~@bringg/react-components/dist/components/task-inventories/task-inventories";
@import "~@bringg/react-components/dist/components/task-inventory/task-inventory.css";
@import "~@bringg/react-components/dist/components/waypoint-times/waypoint-times.css";
@import "~@bringg/react-components/dist/components/task-notes/task-notes";
@import "~@bringg/react-components/dist/components/task-notes-attachments/task-notes-attachments";
@import "~@bringg/react-components/dist/components/customer-contact/customer-contact.css";
@import "~@bringg/react-components/dist/components/rate/rate.css";
@import "~@bringg/react-components/dist/components/task-inventory/task-inventory-form/task-inventory-form.css";
@import "~@bringg/react-components/dist/features/waypoint-card/waypoint-card-form/waypoint-card-form.css";

.order-page {
  margin-left: 25px;
  margin-right: 25px;
  padding: 0;

  .waypoint-card {
    font-size: $font-medium;
    margin-bottom: 15px;
  }

  .spinner-wrapper {
    align-self: center;
  }

  .setting-header-title .ant-btn {
    float: right;
  }
}

// HIDE DELETE BUTTON FROM WAYPOINT EDIT MODAL
.waypoint-card-form .waypoint-card-form-buttons div:first-child button {
  display: none;
}

// HIDE EDIT INVENTORY BUTTON FROM ORDER PAGE
.task-inventory-edit-link {
  display: none;
}

// HIDE ADD NOTE BUTTON FROM ORDER PAGE
.task-notes-add-note {
  display: none;
}
