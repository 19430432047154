.section-block {
  &-title {
    font-size: 18px;

    .question-mark {
      margin-left: 10px;
    }
  }

  &-description {
    color: var(--gray-700);
  }

  &-content {
    background-color: #fff;
    padding: 16px;
    box-shadow: 0 1px 2px rgba(0, 51, 80, 0.16);
    border-radius: 8px;
  }
}
