@import "../../../styles/globals";

.api-settings {
  .copy-to-clipboard {
    float: right;
    margin-right: 10px;
  }

  .api_type_column {
    color: $main-color;
  }
}
