@import "../../styles/globals";

.fleet-select-wrapper {
  .ant-select {
    width: 90%;

    .ant-select-selection--single {
      border-radius: 0;
      border: none;
      border-bottom: 1px solid $select-border-gray;
      height: 27px;
      background-color: transparent;
    }

    .ant-select-selection--single {
      &:focus,
      &:active {
        box-shadow: none;
      }
    }

    .ant-select-arrow .ant-select-arrow-icon {
      margin-top: 3px;
      font-size: 10px;
      margin-right: 0;
    }

    .ant-select-selection--single .ant-select-selection__rendered {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
