@import "../../../../styles/globals";

.ask-for-text {
  @include button;
}

.ask-for-text:hover {
  background-color: $button-color;
  color: $white;
}
