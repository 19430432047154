.sandbox-api-key {
  padding-top: 40px;

  .section-block {
    max-width: 948px;
  }

  .url-copier {
    max-width: 432px;
  }

  .question-mark {
    position: relative;
    top: 1px;
    width: 20px;
    height: 20px;
  }
}
