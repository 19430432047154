@import "../../../../styles/globals";

.admin-credentials {
  @include setting_card;

  .client-id-container {
    margin-bottom: 15px;
  }

  .text-field {
    @include setting-card-text;
  }

  .copy-to-clipboard-button {
    width: 140px;
  }

  .description {
    @include setting_card_description;
  }

  .get-data-button {
    @include button;
  }
}
