.endpoints-list {
  padding: 24px;
  background-color: #f6f6f6;
  font-family: "Open Sans", sans-serif;

  height: calc(100vh - 57px);
  overflow: hidden;

  h1 {
    font-size: 24px;
    color: var(--gray-800);
  }

  h2 {
    font-size: 18px;
    color: var(--gray-800);
  }

  h3 {
    font-size: 16px;
    line-height: 16px;
    color: var(--gray-800);
  }

  p {
    margin-bottom: 20px;
    color: var(--gray-700);
    line-height: 24px;
    max-width: 720px;
  }

  .ant-layout-content {
    position: relative;
  }

  &-action-button {
    font-family: "Open Sans", sans-serif;
    font-size: 16px !important;
    padding: 4px 8px !important;
    color: var(--primary-400) !important;
    line-height: 32px;
    margin-left: 10px;

    span {
      vertical-align: middle;
    }

    img {
      margin-right: 6px;
    }
  }

  &-direction {
    line-height: 12px;
    font-size: 12px;

    span {
      color: var(--gray-800);
      opacity: 0.6;
    }

    img {
      margin-left: 8px;
    }
  }

  &-block {
    padding: 16px;
    background-color: var(--gray-50);
    box-shadow: 0 1px 2px rgba(144, 161, 181, 0.16);
    border-radius: 8px;

    .ant-form-item {
      flex-direction: column;

      .ant-form-item-label {
        text-align: left;
      }

      .ant-form-item-label label {
        color: var(--gray-600);
        font-size: 14px;
        height: 20px;
        line-height: 20px;

        &:after {
          display: none;
        }
      }
    }

    .ant-input {
      max-width: 400px;
      border-radius: 4px;

      & + h4 {
        margin-top: 24px;
      }
    }

    p + & {
      margin-top: 40px;
    }

    & + & {
      margin-top: 32px;
    }

    h3 {
      color: var(--gray-800);
      font-size: 18px;
    }

    h4 {
      color: var(--gray-800);
      font-size: 14px;
    }

    label {
      display: block;
      color: var(--gray-600);
      margin-bottom: 10px;
    }

    span {
      color: var(--gray-600);
    }

    span + h4 {
      margin-top: 24px;
    }

    p {
      color: var(--gray-700);
      font-size: 14px;
    }
  }

  .ant-layout {
    max-height: 100%;
    overflow: hidden;
    background: transparent;

    .ant-layout-sider {
      background: transparent;
    }
  }

  .ant-menu {
    border-radius: 4px;
    overflow: auto;
    height: 100%;
    padding-right: 5px;
    border-right: 0;
    background: transparent;

    .ant-menu-item {
      height: 110px;
      margin: 0 !important;
      border-bottom: 1px solid rgba(#000, 0.1);
      box-shadow: 1px 0px 8px rgba(96, 97, 112, 0.08);
      background-color: #fff;
      transition: initial;
      position: relative;
      overflow: initial;
      padding: 16px 8px 16px 17px;

      &:last-of-type {
        border-bottom: 0;
      }

      &:after {
        display: none;
      }

      &:before {
        content: " ";
        display: none;
        position: absolute;
        top: 50%;
        right: -5px;
        margin-top: -14px;

        width: 0;
        height: 0;
        border-top: 14px solid transparent;
        border-bottom: 14px solid transparent;
        border-right: 16px solid #fff;
      }

      &.ant-menu-item-selected {
        background-color: #eff7fd;
        border-left: 4px solid var(--primary-400);
        padding-left: 13px;

        &:before {
          display: block;
        }

        h3 {
          font-size: 16px;
          color: var(--primary-500);
          font-weight: 600;
        }
      }

      h3 {
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: normal;
        font-size: 16px;
        color: var(--gray-800);
      }
    }
  }

  .ant-layout-content {
    background-color: #fff;
    border-radius: 4px;
    padding: 26px;
  }

  &-checker {
    //color: red;
    margin-bottom: 0;
    //flex-direction: row-reverse;

    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-switch {
      &:after {
        display: none;
      }
    }

    span {
      color: #5b6771;
    }
  }
}
