@import "dispatch-map/dispatch-map";
@import "./dispatch-table/dispatch-table";
@import "../../styles/globals";
@import "task-mapper/task-mapper";

.dispatch {
  margin-left: 25px;
  margin-right: 25px;
  padding: 0;

  .create-task-button {
    border-radius: 4px;
    border: none;
    color: $white;
    background-color: var(--primary-400);
    cursor: pointer;
    margin-right: 10px;
    float: right;
  }

  .quote-task-button {
    border-radius: 4px;
    border: none;
    color: var(--primary-500);
    background-color: var(--primary-100);
    cursor: pointer;
    margin-right: 10px;
    float: right;
  }
}
