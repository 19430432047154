@import "../../../../styles/globals";

.admin-endpoints {
  @include setting_card;

  .text-field {
    @include setting-card-text;
  }

  .copy-to-clipboard-button {
    width: 140px;
  }

  .description {
    @include setting_card_description;
  }
}
