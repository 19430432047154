@import "~antd/dist/antd.css";
@import "~@bringg/react-components/dist/components/floating-placeholder-input/input.css";
@import "./features/features.scss";
@import "./component/components.scss";
@import "~@bringg/react-pages/dist/components/components.css";
@import "./styles/globals.scss";

html {
  --antd-wave-shadow-color: none;
}

body {
  height: 100vh;
  background-color: #f6f6f6;
  font-family: Rubik;
  font-style: normal;
  white-space: pre-line;
}

/**
 RESET NEW BREAKING ANT STYLES
 */

#root {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.ant-table-column-sorters {
  padding: 0;
}

.ant-row {
  //display: block;
}

/**
 RESET NEW BREAKING ANT STYLES
 */

:focus {
  outline: none;
}

.main-color {
  color: $main-color;
}

.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
  padding: 10px 30px;
}

.ant-table-thead > tr > th {
  background-color: $table-header-color;
  max-height: 40px;
}

.ant-table-thead
  > tr
  > th
  .ant-table-header-column
  .ant-table-column-sorters:before {
  background-color: $table-header-color;
}

tbody {
  background-color: $white;
}

.side-menu {
  position: fixed;
}

.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-inner {
  margin-left: 12px;
}

// LINK COLOR
a {
  color: var(--primary-400);
}

// TOOLTIP
.ant-tooltip-inner {
  font-family: "Open Sans", sans-serif;
  line-height: 1;
  background-color: var(--gray-900);
  opacity: 0.88;
  padding: 5px 8px;
  min-height: auto;
  border-radius: 4px;
  font-size: 13px;
}

// NOTIFICATION
.ant-notification {
  width: auto;
  max-width: none;

  .ant-notification-notice-icon {
    display: none;
  }

  .ant-notification-notice {
    width: auto;
    max-width: none;
    padding: 8px 16px;
    margin-right: 16px;
    background-color: var(--gray-900);
    box-shadow: 0 2px 4px rgba(0, 51, 80, 0.04),
      0 8px 16px rgba(0, 51, 80, 0.16);
    text-align: center;
  }

  .ant-notification-notice-message {
    font-family: "Open Sans", sans-serif;
    color: var(--gray-200);
    font-size: 13px;
    margin: 0;
    padding: 0;
    border-radius: 4px;
  }

  .ant-notification-notice-close {
    display: none;
  }
}
