@import "./apis-list/apis-list";
@import "./webhooks-settings/webhooks-apis-form/webhooks-apis-form";
@import "./merchants-list/merchants-list";

// Webhook settings layout fixes
.webhooks-settings {
  .form-page-form {
    width: 100%;

    .webhook-row > div {
      width: 100%;
    }
  }
}

.fleet-name {
  position: absolute;
  margin-top: 15px;
  margin-left: 27px;
  font-weight: 500;
  color: $text-color;
}

.form-save {
  min-height: 0px;
}

.page-header {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}

.page-header .page-header-item .page-header-item-link {
  color: $header-title-color;
}

.setting-body {
  width: 100%;
  margin-left: 25px;
  margin-right: 25px;
}

.sub-title {
  margin-bottom: 5px;
  font-size: $font-medium;
  color: $description-color;
}

.page-navigation {
  background-color: #fff;

  .learn-more-button {
    margin-right: 42px;
  }

  .ant-menu {
    display: flex;
    font-family: "Open Sans", sans-serif;
    border-right: none;
  }

  .ant-menu-item {
    height: 57px;
    line-height: 57px;
    margin-bottom: 0 !important;
    margin-top: 0 !important;

    a {
      color: var(--gray-600);
    }

    &.ant-menu-item-selected {
      background-color: white;
      border-bottom: 2px solid var(--primary-400);

      a {
        color: var(--primary-400);
        font-weight: 600;
      }
    }
  }
}
