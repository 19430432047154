.login {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  font-family: "Open Sans", sans-serif;
  background-color: var(--gray-50);

  &-logo {
    position: absolute;
    top: 60px;
    left: 60px;
    background: url("../../images/logo.svg");
    width: 235px;
    height: 40px;
  }

  &-form {
    width: 400px;

    h1 {
      line-height: 41px;
      margin-bottom: 8px;
      color: var(--gray-800);
    }

    p {
      margin-bottom: 48px;
      line-height: 28px;
      color: rgba(45, 55, 72, 0.7);
    }

    .ant-form-item {
      flex-direction: column;

      .ant-form-item-label {
        text-align: left;
        line-height: 0;

        label {
          color: var(--gray-700);
          height: 18px;
          margin-bottom: 12px;
        }

        label:after,
        label:before {
          display: none;
        }
      }
    }

    .ant-input {
      border-radius: 4px;
    }

    .ant-input-affix-wrapper {
      border-radius: 4px;
      padding: 0;
    }

    .ant-input-affix-wrapper .ant-input:not(:last-child) {
      height: 38px;
      padding-left: 11px;
    }

    .ant-btn-primary {
      background-color: var(--primary-400);
      border-radius: 4px;
    }

    .ant-form-item-control-input-content {
      line-height: 0;
    }
  }

  &-forget {
    display: inline-flex;
    color: rgba(45, 55, 72, 0.7);
    text-decoration: underline;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 48px;

    &:hover {
      color: rgba(45, 55, 72, 0.7);
    }
  }
}
