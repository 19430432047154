:root {
  --gray-500-rgb: 160, 174, 192;
  --gray-50: #fafbfc;
  --gray-100: #f2f6f8;
  --gray-200: #e8ecf3;
  --gray-300: #dbe1eb;
  --gray-400: #cbd5e0;
  --gray-500: rgb(var(--gray-500-rgb));
  --gray-600: #718096;
  --gray-650: #768495;
  --gray-700: #4a5568;
  --gray-800: #2d3748;
  --gray-900: #1a202c;
  --gray-500-a16: rgba(var(--gray-500-rgb), 0.16);
  --primary-10: #eff4f8;
  --primary-25: #edf5fd;
  --primary-50: #e1f0fd;
  --primary-100: #cce9ff;
  --primary-200: #a7d9f8;
  --primary-300: #6fb2dd;
  --primary-400: #3091ce;
  --primary-500: #067eba;
  --primary-600: #006497;
  --primary-650: #005784;
  --primary-700: #004b73;
  --primary-750: #003f61;
  --primary-800: #003350;
  --primary-900: #001e31;
  --success-50: #e5f8ec;
  --success-100: #c1edd1;
  --success-400: #35cd7c;
  --success-500: #00c364;
  --warning-50: #fff8e1;
  --warning-100: #ffecb3;
  --warning-600: #ffb400;
  --warning-900: #ff7001;
  --danger-50: #ffebee;
  --danger-100: #ffccd3;
  --danger-500: #ff3333;
  --danger-600: #f02733;
  --ins-300: #ef53b1;
  --ins-900: #860069;
  --white-text: #ffffff;
}
