.registered-merchants {
  padding: 24px;
  background-color: #f6f6f6;
  font-family: "Open Sans", sans-serif;

  h1 {
    font-size: 24px;
  }

  p {
    color: var(--gray-700);
  }

  .section-block {
    margin-top: 32px;

    .section-block-content {
      padding-top: 0;
    }

    .url-copier {
      width: 480px;
      padding-top: 16px;
    }
  }

  // Table
  .ant-table-wrapper {
    .ant-table-container {
      box-shadow: 0 1px 2px rgba(0, 51, 80, 0.16);
      border-radius: 8px;
      border: 1px solid var(--gray-100);
      overflow: hidden;
    }

    .ant-table {
      background: none;
    }

    .ant-table-tbody {
      background: #fff;
    }

    .ant-table-thead tr > th {
      padding: 0 24px;
      max-height: 52px;
      height: 52px;
      background-color: var(--gray-100);
      font-weight: 600;
      color: var(--gray-800);
      font-size: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .ant-table-tbody > tr > td {
      border-bottom: 0;
      height: 73px;
      color: var(--gray-700);
      padding: 10px 24px;
    }

    .ant-table-tbody > tr.ant-table-row:hover > td {
      background-color: var(--gray-50);
    }
  }

  &-cell {
    display: flex;
    align-items: center;
    white-space: nowrap;

    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
