@import "./colors.scss";

$text-color: #000;
$main-color: #3d8deb;
$button-color: #65acfe;
$white: #fff;
$light-gray: #eee;
$header-background-color: #fff;
$gray: #d3d3d3;
$sub-title-color: #494949;
$description-color: #929292;
$background-color: #fff;
$warning-color: #ffcf02;
$red: #f4371e;
$header-title-color: #828282;
$select-border-gray: #e1e1e1;
$table-header-color: #e1e1e1;

$font-xl: 24px;
$font-large: 18px;
$font-medium: 14px;
$font-small-medium: 13px;
$font-small: 12.5px;

@mixin button() {
  background-color: $button-color;
  color: $white;
  height: 25px;
  border: none;
  padding-top: 2px;
  cursor: pointer;
}

@mixin setting_card() {
  background-color: $white;
  border: 1px solid $light-gray;
  border-radius: 8px;
  padding: 20px;
}

@mixin setting_card_text() {
  font-size: $font-large;
  margin-right: 20px;
  color: $text-color;
}

@mixin setting_card_description {
  margin-top: 10px;
  font-size: $font-medium;
  color: $sub-title-color;
}
