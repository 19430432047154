@import "../../styles/globals";

.setting-header {
  margin-top: 20px;
  margin-bottom: 20px;

  .setting-header-title {
    font-size: $font-xl;
    font-weight: 500;
    color: $text-color;
    margin-bottom: 20px;
  }

  .setting-header-description {
    color: $sub-title-color;
    font-size: $font-medium;
  }
}
