.learn-more-button {
  color: var(--primary-400) !important;

  &:hover {
    color: var(--primary-400) !important;
  }

  img {
    margin-left: 5px;
  }
}
