@import "../../../styles/globals";
@import "~mapbox-gl/dist/mapbox-gl.css";

.map-container {
  padding: 15px;
  background-color: $white;
  border-radius: 4px;
  width: 100%;
  display: flex;
  justify-content: center;

  .mapbox-container {
    width: 100%;
    height: 340px;

    .mapboxgl-ctrl-attrib-inner {
      display: none;
    }
  }

  .mapboxgl-popup-content {
    padding: 7px;
    font-family: Rubik;
  }

  .spinner {
    align-self: center;
  }
}
