.welcome-page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: scroll;
  overflow-y: scroll;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fafbfc;
  max-height: calc(100% - 72px);

  font-family: "Open Sans", sans-serif;

  &-container {
    margin: 0 auto;
    padding: 60px;
  }

  &-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    text-align: right;
    padding: 16px 24px;
    box-shadow: 1px 0 8px rgba(96, 97, 112, 0.08);
    background-color: #fff;

    .ant-btn-primary {
      background-color: var(--primary-400);
      border-radius: 4px;
      padding: 0 24px;
    }
  }

  &-title {
    margin-top: 56px;

    h1 {
      margin-bottom: 13px;
      font-weight: 400;
      color: var(--gray-800);
      font-family: "Open Sans", sans-serif;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      width: 845px;
      margin-bottom: 18px;
      color: rgba(45, 55, 72, 0.7);
    }
  }

  &-logo {
    background: url("../../images/logo.svg");
    width: 215px;
    height: 40px;
  }
}

.delivery-model {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  margin-top: 40px;

  &-item {
    &-title-container {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }

    h2 {
      margin-bottom: 16px;
      font-size: 18px;
      font-weight: 600;
      line-height: 25px;
      text-transform: capitalize;
      color: var(--gray-800);
    }
  }

  &-steps {
    display: flex;
    align-items: center;
    column-gap: 3px;
  }

  &-step {
    background: #ffffff;
    width: 164px;
    height: 95px;
    border: 1px solid var(--gray-800);
    border-radius: 8px;
    padding: 16px;

    h3 {
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      text-transform: capitalize;
      color: var(--gray-700);
    }

    span {
      font-size: 10px;
    }

    p {
      margin: 0;
      color: var(--gray-700);
      opacity: 0.6;
      font-size: 12px;
    }
  }

  .question-mark {
    position: relative;
    top: 2.5px;
    width: 20px;
    height: 20px;
  }
}

.arrow-right {
  background: url("../../images/arrow-right.svg");
  width: 22px;
  height: 8px;
}

.tool-tip-custom {
  .ant-tooltip-arrow {
    margin-left: 0px !important;
  }

  .ant-tooltip-inner {
    margin-left: 0px !important;

    background: #1e2634;
    border-radius: 4px;
    min-width: max-content;
    white-space: nowrap;

    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: #ffffff;
  }
}

.delivery-model-step.additional-background {
  margin-top: 10px;
  box-shadow: 10px -10px white, 10px -10px 0 1px #2d3748;
  margin-right: 11px;
}

.tool-tip-custom.additional-background {
  .ant-tooltip-arrow {
    margin-bottom: 10px !important;
  }

  .ant-tooltip-inner {
    margin-bottom: 10px !important;
  }
}
