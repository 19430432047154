.url-copier {
  max-width: 590px;

  & + & {
    margin-top: 16px;
  }

  &-title {
    margin-bottom: 8px;
    color: var(--gray-600);
    font-size: 14px;
  }

  .ant-input-affix-wrapper {
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 4px;
    border-color: var(--gray-300);
  }

  .ant-input-affix-wrapper {
    background-color: #fff;
    font-size: 16px;
  }

  .ant-input-affix-wrapper::before {
    display: none;
  }

  .ant-input-affix-wrapper .ant-input-suffix {
    right: 0;
  }

  .ant-input {
    font-family: "Open Sans", sans-serif;
    color: var(--gray-600) !important;
    max-width: 100% !important;
  }

  .ant-input-group-addon {
    background-color: var(--gray-100);
    border-color: var(--gray-300);
    border-radius: 4px 0 0 4px;
    color: var(--gray-800);
  }
}
