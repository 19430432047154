@import "../../../styles/globals";

.dispatch-table {
  .ant-table-wrapper {
    background-color: transparent;
    margin-bottom: 15px;
    border-radius: 4px;
  }

  .ant-table-header {
    border-radius: 4px;
    font-size: $font-small-medium;
  }

  .ant-table-body {
    font-size: $font-small-medium;
    min-height: 363px;
    max-height: 363px;
    border-radius: 4px;
    overflow-y: scroll;
    background-color: $white;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: $gray;
      outline: 1px solid $light-gray;
    }
  }

  .ant-table-placeholder {
    display: none;
  }

  .ant-table-tbody > tr > td:first-child {
    padding-left: 20px;
  }

  .ant-table-thead {
    tr {
      th,
      td {
        background-color: $table-header-color;
      }
    }
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    padding: 3px 8px;
  }
}
