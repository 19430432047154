@import "../../../../styles/globals";

.copy-to-clipboard-icon {
  color: $main-color;
}

.merchant-cell {
  word-wrap: break-word;
  word-break: break-word;
  display: inline-block;

  svg {
    cursor: pointer;
    margin-bottom: 5px;
  }

  .column-text {
    max-width: 200px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 5px;
  }
}
