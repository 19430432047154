.copy-to-clipboard-button {
  border-radius: 4px;
  border: none;
  color: $white;
  background-color: $button-color;
  cursor: pointer;
  font-size: $font-small;
  height: 22px;
  width: 70px;
}

.copy-to-clipboard {
  background-color: transparent;
}
