@import "../../../../styles/globals";

.webhooks-apis-form {
  .webhook-row {
    padding: 20px;
  }

  .webhooks-list-container,
  .key-container {
    @include setting_card;
  }

  .webhooks-list-container {
    margin-bottom: 20px;

    .full-payload-on-update {
      display: flex;
      place-items: flex-start;
      margin-top: 16px;
      .checkbox {
        margin-top: 3px;
      }

      .checkbox-label {
        margin-left: 8px;
      }

      .question-icon {
        margin-left: 8px;
        margin-top: 3px;
        color: $button-color;
      }
    }
  }

  .key-container {
    padding: 20px;
  }

  .webhook-key {
    @include setting-card-text;
  }

  .key-description {
    @include setting_card_description;
  }

  .webhook-mandatory {
    color: $red;
  }
}
