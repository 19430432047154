@import "../../../styles/globals";
@import "./merchant-cell/merchant-cell";
@import "./hidden-cell/hidden-cell";
@import "./admin-credentials/admin-credentials";
@import "./admin-endpoints/admin-endpoints";

.merchants-list {
  .ant-table-wrapper {
    border-radius: 4px;
    margin-bottom: 20px;
  }

  .admin-endpoints {
    margin-bottom: 20px;
  }

  .ant-table-body {
    min-height: 250px;
    max-height: 250px;
    border-radius: 4px;
    overflow-y: auto;
    background-color: $white;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: $gray;
      outline: 1px solid $light-gray;
    }
  }

  .ant-table-tbody > tr > td {
    > tr {
      > td {
        width: 20%;
      }
    }
  }
}
