@import "../../../styles/globals";

.task-mapper {
  .ant-modal-content {
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04);
  }

  h1 {
    color: var(--gray-800);
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  p {
    color: var(--gray-700);
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 24px;
  }

  .radio-container {
    display: flex;
    justify-content: space-between;
  }

  .ant-table-wrapper {
    border: 1px solid #f8fafb;
    box-shadow: 0px 1px 2px rgba(0, 51, 80, 0.16);
    border-radius: 4px;
  }

  .ant-table table {
    border-collapse: collapse;
  }

  .ant-table colgroup > col.ant-table-selection-col {
    width: 73px;
  }

  thead > tr > th {
    height: 54px;
  }

  .ant-table-body {
    overflow-y: auto;
  }

  tbody > tr > td {
    height: 54px;
  }

  .ant-table-thead > tr > th {
    background-color: #ffffff;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin: 5px;
  }

  .ant-table-tbody > tr > td {
    background: rgba(188, 207, 219, 0.1);
  }

  .ant-table-tbody > tr > td:nth-of-type(2) {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #913441;
    border-right: 5px solid #ffffff;
    border-left: 5px solid #ffffff;
  }

  .ant-table-tbody > tr > td:nth-of-type(3) {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: var(--gray-800);
  }

  .ant-checkbox-inner {
    width: 18px;
    height: 18px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #3091ce;
    border-color: #3091ce;
  }

  .ant-modal-footer {
    padding: 0 24px 24px 24px;
    border: none;
  }

  .ant-modal-footer > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ant-btn {
      padding: 0;
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #3091ce;
      border: none;
      box-shadow: none;
    }

    .ant-btn.ant-btn-primary {
      color: #ffffff;
      padding: 8px 48px;
      background: #3091ce;
      border-radius: 4px;
      height: 40px;
    }
  }

  .json-input-container {
    border: 1px solid #f8fafb;
    box-shadow: 0px 1px 2px rgba(0, 51, 80, 0.16);
    border-radius: 4px;
    padding: 23px;

    #a_unique_id-outer-box {
      padding: 5px;
      border: 1px solid #f8fafb;
      border-radius: 4px;
    }

    .ant-btn {
      display: block;
      margin-left: auto;
      margin-right: 0;
      padding: 0;
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #3091ce;
      border: none;
      box-shadow: none;
    }
  }
}
